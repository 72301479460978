.situational-menu {
    height: 30px;
    width: 100%;
    background-color: #3f51b4c4;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 40px;
    box-shadow: 0 0px 5px 0px #000000;

    ul {
        margin: 0;
        padding: 0;

        li {
            color: #ffffffab;
            display: inline-block;
            font-size: 0.8em;
            line-height: 20px;
            margin: 0;
            padding: 5px;
            transition: color, font-size 0.3s ease-in;

        }

        li:hover {
            cursor: pointer;
            border-radius: 2px;
            color: #ffffff;
        }
    }
}