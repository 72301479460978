.context-menu {
    background: white;
    position: absolute;
    z-index: 1;
    width: max-content;
    min-width: 250px;
    left: 272px;
    top: 68px;
    box-shadow: 1px 1px 5px #96989e;

    &__items {
        list-style: none;
        padding: 15px;
        font-size: 15px;
        margin: 0;
    }

    &__item {
        line-height: 30px;
        transition: all 0.4s ease;
            padding: 0 10px;
        transition: all 0.3s ease-out;

        &:hover {
            cursor: pointer;
            background-color: #3f51b4;;
            color: white;
        }
    }

    &__blank {
        height: 1px;
        background-color: #d4c7c7;
        width: 100%;
        border: 0;
        margin: 3px;
    }

    // & > ul {
    //     list-style: none;
    //     padding: 15px;
    //     font-size: 15px;
    //     margin: 0;

    //     & > li {
    //         line-height: 30px;
    //         transition: all 0.4s ease;
    //             padding: 0 10px;
    //         transition: all 0.3s ease-out;

    //         &:hover {
    //             cursor: pointer;
    //             background-color: #3f51b4;;
    //             color: white;
    //         }
    //     }

    //     hr {
    //         height: 1px;
    //         background-color: #d4c7c7;
    //         width: 100%;
    //         border: 0;
    //         margin: 3px;
    //     }
    // }
}