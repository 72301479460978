// @import "../../styles/Buttons.scss";

.promt {
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 400px;
    height: 150px;
    background-color: white;
    z-index: 100;
    left: calc(50% - 200px);
    box-shadow: 0px 0px 10px 4px #00000075;
    top: 31px;

    &__text-wrapper {
        display: flex;
        height: 80%;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }

    &__text-wrapper-content {
        text-align: center;
    }

    &__controls {
        display: flex;
        justify-content: space-around;
        padding: 15px;
        height: 20%;
    }

}
