.menu-items {
    list-style: none;
    padding: 15px;
    font-size: 15px;
    margin: 0;


    &__item {
        display: flex;
        line-height: 35px;
        padding: 0px 10px;
        transition: all 0.3s ease-out;

        a {
            display: flex;
            text-decoration: none;
            width: 100%;
            color: black;
        }

        &:hover {
            cursor: pointer;
            background-color: #3f51b4;
            color: white;

            a { 
                color: white;
            }

            & > div svg { 
                fill: white!important;
            }

            a div svg {
                fill: white;
            }
        }

        div:first-child {
            display: flex;
            align-items: center;
            margin-right: 10px;

            svg {
                fill: #919191;
            }
        }
    }
}

