%menu {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    padding: 1px 17px 1px 18px;
    box-sizing: border-box;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    z-index: 2;
    user-select: none;

    &__status-panel {
        height: 40px;
        width: 100%;
        background-color: var(--main-color);
        position: absolute;
        z-index: 3;
        box-shadow: 0px 0px 5px 0px black;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        color: white;
        text-shadow: 1px 1px 1px #000000;
        font-size: 0.9em;
    }

    &__bg-left-wing {
        height: 70px;
        width: 250px;
        transform: skew(-25deg);
        background-color: #3f51b4;
        position: relative;
        left: -17px;
        box-shadow: 2px 0px 3px 0px #636363;
    }

    &__front-left-wing {
        height: 70px;
        width: 250px;
        transform: skew(-25deg);
        background-color: #3f51b4;
        position: absolute;
        left: -17px;
        top: 0;
        z-index: 3;
    }

    &__left-menu {
        @extend %menu;
        transform: skew(25deg);
    }

    &__logo {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 60px;
        margin-left: 5px;
    }

    &__logo-content {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #00000036;
        box-shadow: 0px 0px 10px #373737;
        overflow: hidden;
        transition: all 0.5s ease;

        &:hover {
            cursor: pointer;
            background-color: #00000075;
            box-shadow: 0px 0px 14px #000000;
        }
    }

    &__bg-right-wing {
        height: 70px;
        width: 250px;
        transform: skew(25deg);
        background-color: #3f51b4;
        position: absolute;
        right: -17px;
        top: 0;
        box-shadow: -2px 0px 3px 0px #636363;
    }

    &__front-right-wing {
        height: 70px;
        width: 250px;
        transform: skew(25deg);
        background-color: #3f51b4;
        position: absolute;
        right: -17px;
        top: 0;
        z-index: 3;        
    }

    &__right-menu {
        @extend %menu;
        transform: skew(-25deg);
        justify-content: center;
        align-items: center;
    }

    &__menu-item {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 0 4px;
        transition: all 0.5s cubic-bezier(0, 0, 0.39, 1.02);


        & > a {
            position: relative;
        }
        
        &:hover {
            cursor: pointer;
            background-color: #ffffff40;
            box-shadow: 0px 0px 7px 0px #ffffffc2;
        }

        &:active {
            background-color: #ffffff82;
        }
    }


    &__menu-item_unsave > a:after  {
        content: '';
        position: absolute;
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: white;
        left: calc(50%);
        margin-top: 7px;
        box-shadow: 0px 0px 5px 2px white;
    }

    &__menu-item_active {
        cursor: pointer;
        background-color: #ffffff26;
        box-shadow: 0px 0px 3px 1px #ffffff;
        border-radius: 2px;
    }
}


// section.header{
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     height: 70px;
//     z-index: 2;
//     user-select: none;
    
//     & > .status-line-panel {
//         height: 40px;
//         width: 100%;
//         background-color: #3f51b4;
//         position: absolute;
//         z-index: 3;
//         box-shadow: 0px 0px 5px 0px black;
//         display: flex;
//         flex-flow: row;
//         justify-content: center;
//         align-items: center;

//         & > .status-line {
//             color: white;
//             text-shadow: 1px 1px 1px #000000;
//             font-size: 0.9em;
//         }
//     }

//     & > .bg-left-wing {
//         height: 70px;
//         width: 250px;
//         transform: skew(-25deg);
//         background-color: #3f51b4;
//         position: relative;
//         left: -17px;
//         box-shadow: 2px 0px 3px 0px #636363;
//     }

//     & > .front-left-wing {
//         height: 70px;
//         width: 250px;
//         transform: skew(-25deg);
//         background-color: #3f51b4;
//         position: absolute;
//         left: -17px;
//         top: 0;
//         z-index: 3;

//         .left-menu {
//             @extend %menu;
//             transform: skew(25deg);

//             .logo {
//                 display: flex;
//                 flex-flow: column nowrap;
//                 justify-content: center;
//                 align-items: center;
//                 height: 100%;
//                 min-width: 60px;
//                 margin-left: 5px;

//                 .logo-wrapper {
//                     width: 60px;
//                     height: 60px;
//                     border-radius: 50%;
//                     background-color: #00000036;
//                     box-shadow: 0px 0px 10px #373737;
//                     overflow: hidden;
//                     transition: all 0.5s ease;

//                     &:hover {
//                         cursor: pointer;
//                         background-color: #00000075;
//                         box-shadow: 0px 0px 14px #000000;
//                     }
//                 }
//             }
//         }
//     }

//     & > .bg-right-wing {
//         height: 70px;
//         width: 250px;
//         transform: skew(25deg);
//         background-color: #3f51b4;
//         position: absolute;
//         right: -17px;
//         top: 0;
//         box-shadow: -2px 0px 3px 0px #636363;
//     }

//     & > .front-right-wing {
//         height: 70px;
//         width: 250px;
//         transform: skew(25deg);
//         background-color: #3f51b4;
//         position: absolute;
//         right: -17px;
//         top: 0;
//         z-index: 3;

//         .right-menu {
//             @extend %menu;
//             transform: skew(-25deg);
//             justify-content: center;
//             align-items: center;

//             .nav-menu-item {
//                 display: flex;
//                 flex-flow: column nowrap;
//                 justify-content: center;
//                 align-items: center;
//                 width: 50px;
//                 height: 50px;
//                 margin: 0 4px;
//                 transition: all 0.5s cubic-bezier(0, 0, 0.39, 1.02);

//                 & a {
//                     position: relative;
//                 }
                
//                 &.unsave > a:after {
//                     content: '';
//                     position: absolute;
//                     display: block;
//                     width: 3px;
//                     height: 3px;
//                     border-radius: 50%;
//                     background-color: white;
//                     left: calc(50%);
//                     margin-top: 7px;
//                     box-shadow: 0px 0px 5px 2px white;
//                 }

//                 &.active {
//                     cursor: pointer;
//                     background-color: #ffffff26;
//                     box-shadow: 0px 0px 3px 1px #ffffff;
//                     border-radius: 2px;
//                 }

//                 &:hover {
//                     cursor: pointer;
//                     background-color: #ffffff40;
//                     box-shadow: 0px 0px 7px 0px #ffffffc2;
//                 }

//                 &:active {
//                     background-color: #ffffff82;
//                 }
//             }

//         }
//     }
// }