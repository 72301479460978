.popup {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: center;
    position: relative;

    width: 230px;
    min-height: 35px;

    color: #ffffff;
    text-shadow: 1px 1px 3px #000000;

    right: 10px;
    bottom: 10px;

    font-size: 15px;

    padding: 10px;
    margin-top: 6px;
    
    user-select: none;

    @each $type, $bgcolor, $shadowcolor in (warning, #ed9222e8, #ed9222), 
                                            (success, #3f51b4e8, #3f51b4), 
                                            (error, #e23636e8, #e23636){
        &.#{$type} {
            background-color: $bgcolor;
            box-shadow: 0px 0px 4px 0px $shadowcolor;
        }
    }

    &:after{
      content: '';
      position: absolute;
      display: block;
      z-index: 1;
      height: 100%;
      width: 100%;
      background-color: #26252526;
      left: 0;
      top: 0;
      animation: expiredeLine 3.5s linear forwards;
    }


    &:hover {
      background-color: #00000085;
      transition: all 0.5s ease;
      color: #ffffff00;
      box-shadow: 1px 1px 5px #00000085;
      text-shadow: 1px 1px 3px #f9f9f9;

      &:before {
        content: 'Закрыть';
        cursor: pointer;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: #ffffff;
        font-size: 16px;
        text-shadow: 2px 2px 1px black;
        z-index: 2;
      }
    }


}

@keyframes expiredeLine {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }