// 

$margin-sizes: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60);

@each $size in $margin-sizes {
    .ml-#{$size} { margin-left: $size + px;}
    .mr-#{$size} { margin-right: $size + px;}
    .m-#{$size} { margin: $size + px;}
}

//


.root {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: #3f51b447;
    // background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);

    &_fullscreen {
        top: 0;
    }

    


}
