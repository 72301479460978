@import "../../styles/Patterns.scss";


.page {

    height: 100%;
    overflow: auto;
    transition: all 1s ease-in-out;

}

.authorization {
    @extend %page-content;
}