::-webkit-scrollbar {
    width: 5px;

    &-#{"track"} {
        background-color: #4453a500;
    }

    &-#{"thumb"} {
        background-color: #3f51b45c;
        border-radius: 5px;
    }
}