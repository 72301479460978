.bank-info {
    width: 100%;
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    &__content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    &__content-row {
        display: block;
        font-size: 14px;
        color: #ffffff;
        text-shadow: -1px 1px 3px #000000;
    }

    &__content-row-title {
        font-size: 12px;
        color: #fcfcfccc;
    }

    // &__content-row {
    //     font-size: 14px;
    //     color: #ffffff;
    //     text-shadow: -1px 1px 3px #000000;
    // }
}

.user-info {
    // display: grid;
    // grid: 50% 50% / 50% 50%;
    // width: 100%;
    // height: 100%;
    // margin-left: 5px;
    // margin-right: 5px;
    // display: flex;
    // flex-flow: column nowrap;
    // justify-content: center;

    // .user-menu {
    //     display: flex;
    //     flex-flow: column nowrap;
    //     justify-content: center;
    //     align-items: flex-start;
    //     grid-column: 1 / span 2;

    //     & > span {
    //         display: block;

    //         &:first-child {
    //             font-size: 10px;
    //             color: #ffffff7a;
    //         }

    //         &:last-child {
    //             font-size: 12px;
    //             color: #ffffff;
    //             text-shadow: -1px 1px 3px #000000;
    //         }
    //     }

    // }

    // .bank-info {
    //     display: flex;
    //     flex-flow: column nowrap;
    //     justify-content: center;
    //     align-items: center;
    //     // background-color: #969ec89e;
    //     // box-shadow: inset 0px 0px 3px 0px #000000b8;


    //     & > span {
    //         display: block;

    //         &:first-child {
    //             font-size: 12px;
    //             color: #fcfcfccc;
    //         }

    //         &:last-child {
    //             font-size: 14px;
    //             color: #ffffff;
    //             text-shadow: -1px 1px 3px #000000;
    //         }
    //     }
    // }

    // .app-menu {
    //     display: flex;
    //     flex-flow: row nowrap;
    //     justify-content: center;


    //     .sub-menu {
    //         position: absolute;
    //         width: 130px;
    //         top: 75px;

    //         .sub-menu-fly-item {
    //             position: absolute;
    //             display: flex;
    //             flex-flow: column nowrap;
    //             justify-content: center;
    //             align-items: center;
    //             width: 45px;
    //             height: 45px;
    //             background-color: #3f51b4;
    //             border-radius: 50%;
    //             box-shadow: 1px 1px 4px 1px #5d5d5d;

    //             &.first {
    //                 left: 60px;
    //                 top: -50px;

    //                 // left: 0px;
    //                 // top: 0px;
    //             }

    //             &.second {
    //                 left: 60px;
    //                 top: -50px;

    //                 // left: 60px;
    //                 // top: 0;
    //             }

    //             &.third {
    //                 left: 60px;
    //                 top: -50px;

    //                 // left: 120px;
    //                 // top: 0;
    //             }
    //         }
    //     }

    //     .app-menu-item {
    //         display: flex;
    //         flex-flow: column nowrap;
    //         justify-content: center;
    //         align-items: center;
    //         height: 30px;
    //         width: 30px;
    //         margin: 0 2px;
    //         transition: all 0.5s cubic-bezier(0, 0, 0.39, 1.02);

    //         & a {
    //             height: 18px;
    //         }

    //         &:hover {
    //             cursor: pointer;
    //             background-color: #ffffff40;
    //             box-shadow: 0px 0px 7px 0px #ffffffc2;
    //         }

    //         &:active {
    //             background-color: #ffffff82;
    //         }
    //     }

    // }
}