// %button {

//     border: none;

//     height: 30px;
    
//     font: {
//         size: 15px;
//     }
//     outline: 0;
//     width: 100px;
//     line-height: 30px;

//     transition: all 0.3s ease-in-out;

//     padding: 0;
//     box-shadow: 2px 2px 1px 0px #3f51b459;

//     & > a {
//         display: block;
//         color: white;
//         text-decoration: none;
//     }

//     &:hover {
//         cursor: pointer;
//         background-color: #3f51b4d9;
//     }

//     &:active {
//         background-color: #3f51b49c;
//     }

//     &:disabled {
//         background-color: #3f51b49c;
//     }
// }

// color: #4e4d4d;
// background-color: #f4f4f4;


// button.main {
//     @extend %button;

//     color: white;
//     background-color: #3f51b4;
// }

// button.secondary {
//     @extend %button;

//     color: #4e4d4d;
//     background-color: #f4f4f4;

//     &:hover {
//         cursor: pointer;
//         background-color: #b6b6b694;
//         color: #00000087;
//     }

//     &:active {
//         background-color: #5858585c;
//         color: #0303038c;
//     }
// }

// BEM

.button {
    height: 30px;
    min-width: 100px;

    font-size: 15px;
    line-height: 30px;
    
    padding: 0 5px;
    outline: 0;
    border: none;
    margin: 0 5px;

    transition: all 0.3s ease-in-out;

    &__main {
        box-shadow: 2px 2px 1px 0px lighten($mainColor, 30%);
        color: white;
        background-color: $mainColor;

        &:hover {
            background-color: lighten($mainColor, 10%);
        }

        &:active {
            background-color: lighten($mainColor, 15%);
        }
    
        &:disabled {
            background-color: lighten($mainColor, 20%);
        }
    }

    &__danger {
        box-shadow: 2px 2px 1px 0px lighten(red, 30%);
        color: white;
        background-color: red;

        &:hover {
            background-color: lighten(red, 10%);
        }

        &:active {
            background-color: lighten(red, 15%);
        }
    
        &:disabled {
            background-color: lighten(red, 20%);
        }
    }

    &__secondary {
        box-shadow: 2px 2px 1px 0px darken(#f4f4f4, 30%);
        color: #4e4d4d;
        background-color: #f4f4f4;

        &:hover {
            background-color: darken(#f4f4f4, 10%);
        }

        &:active {
            background-color: darken(#f4f4f4, 15%);
        }
    
        &:disabled {
            background-color: darken(#f4f4f4, 20%);
        }
    }

    &__link {
        display: block;
        color: white;
        text-decoration: none;
    }


    &:hover {
        cursor: pointer;
    }

    
}