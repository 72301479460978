.loader {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: #d8dae6;
    transition: all 0.3s ease;
    opacity: 0;
    pointer-events: none;

    .loader-content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        pointer-events: initial;
    }

    &.show {
        opacity: 1;
    }
    &.hide {
        animation-name: opacityHide;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }




}

@keyframes opacityShow {
    from {
        opacity: 0;
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
    to {
        opacity: 1;
    }
}

@keyframes opacityHide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}