$inputTypes: ('text', 'password');

.form {

    align-items: center;

    background-color: white;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: 4px 4px 10px 2px #868686;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    width: 350px;

    &__body{

        box-sizing: border-box;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        padding: 40px 20px;

        width: 100%;
    }

    &__body-title {
        display: block;
        text-align: center;
        font-size: 22px;
        margin-top: -15px;
        margin-bottom: 20px;
        color: #a6a6a6;
    }

    &__body-row {
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__body-input {
        box-sizing: border-box;
        border: 0;        
        color: #3f51b4;
        outline: 0;
        font-size: 15px;
        padding: 10px 10px 10px 10px;
        text-align: center;
        width: 100%;
    }

    &__body-label {
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        line-height: 37px;
        text-align: center;
        font-size: 14px;
        pointer-events: none;
        box-shadow: 2px 2px 2px 0px #3f51b475;
        background-color: #3f51b421;
        transition: all 0.6s ease;
    }

    &__body-label-content {
        display: inline-block;
        transition: all 0.5s ease-in-out;
        color: #00000087;
        text-shadow: 1px 1px 1px white
    }

    @each $type in $inputTypes {
        input[type='#{$type}']:focus + &__body-label &__body-label-content, 
        input[type="#{$type}"]:valid + &__body-label &__body-label-content {
            transform: scaleY(0.5);
            opacity: 0;
        }

        input[type="#{$type}"]:focus + &__body-label {
            box-shadow: 1px 1px 9px #3f51b4a3;
            background-color: #3f51b400;
        }
    }
}