@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import "./styles/Colors.scss";
@import "./styles/Buttons.scss";
@import "./styles/Scrollbar.scss";
@import "./styles/Form.scss";
@import "./styles/Menu.scss";

body {
    font-family: 'Roboto', sans-serif;
    margin: 0px; 
}

#loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    z-index: 1;
}